import plx from "@/assets/withdrawal/plx.inline.png";
import renwu from "@/assets/withdrawal/renwu.inline.png";
import suo from "@/assets/withdrawal/suo.inline.png";
import xinfeng01 from "@/assets/withdrawal/xinfeng01.inline.png";
import yhk from "@/assets/withdrawal/yhk.inline.png";
import suot from "@/assets/withdrawal/suot.inline.png";
import g_gou from "@/assets/withdrawal/g_gou.inline.png";
import dl_sj from "@/assets/common/dl_sj.inline.png";
import ts from "@/assets/common/ts.inline.png";
import sc2 from "@/assets/common/sc2.inline.png";
import srgb from "@/assets/common/srgb.inline.png";
import add from "@/assets/common/+.inline.png";
import chenggong_icon from "@/assets/withdrawal/chenggong_icon.inline.png";
import cg from "@/assets/common/cg.inline.png";
import shenhe from "@/assets/common/shenhe.inline.png";
import sb from "@/assets/common/sb.inline.png";

export const pngList = {
  plx,
  renwu,
  suo,
  xinfeng01,
  yhk,
  suot,
  g_gou,
  dl_sj,
  ts,
  sc2,
  srgb,
  add,
  chenggong_icon,
  cg,
  shenhe,
  sb,
};
